// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  MIS_DASHBOARD: 'XXXX',

  ISSUER: 'https://login.microsoftonline.com/dc628a2f-bbfd-4e17-8fd6-1f22fffd2b23/v2.0',
  REDIRECT_URI: 'https://qwyn.deltacapita.net',
  CLIENT_ID: '04d2df8e-6937-4cfb-ae4e-b858619cb656',
  SCOPE: 'openid profile api://04d2df8e-6937-4cfb-ae4e-b858619cb656/sso_application',
  RESPONSE_TYPE: 'code',
  QWYNUI_URL: 'https://qwyn.deltacapita.net',
  QMAAPI_URL: 'https://qmauiapi.qwyn.deltacapita.net',
  TIMEOUT_FACTOR: 0.80
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
